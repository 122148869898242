export const FEATURE_FLAGS = {
  HIDE_MESSAGES_CONTENT: 0,
};

export const FEATURE_FLAGS_KEYS = {
  ATTACHMENT_SIZE_LIMITATION: 'attachment-size-limitation',
  QUEUE_INLINE_EDIT: 'queue-inline-edit',
  SHOW_EXTERNAL_PAGES_IN_IFRAME: 'show-external-links-in-iframe',
  PROJECT_ONE_ENABLED: 'project-one',
  SPACES_ACTIVITIES_MVP: 'spaces_activities_mvp',
  REAL_TIME_UPDATES: 'real_time_updates',
  AUTO_POPULATED_TICKETS_SPACES: 'ai-auto-populating-tickets-in-spaces',
  ADMIN_CHATBOT_ENABLED: 'admin-chatbot-copilot',
  COPILOT_SPACES_LOBBY_SETTINGS: 'copilot-spaces-lobby-settings',
  SHOW_FEEDBACK_ON_SWITCH_TO_CLASSIC: 'show-feedback-modal-on-switch-to-classic',
  RELATED_ITEMS_SPACES_MVP: 'related-items-spaces-mvp',
  RELATED_ITEMS_SPACES_MVP_SR_TO_ASSET_AND_CI: 'related-items-spaces-mvp-sr-to-asset-an-ci',
  MENU_ITEM_TOGGLE_ENABLED: 'menu-item-toggle-enabled',
  SR_CONVERSION: 'convert-incident-request-in-spaces',
  OPEN_SR_FROM_QUEUE: 'open-sr-from-queue',
  SHOW_RESOURCE_CENTER_MENU_ITEM: 'show-resource-center-menu-item',
  ENABLE_SR_TYPE_CHANGE_ON_TICKET_CREATION: 'change-sr-type-from-ticket-creation-in-spaces',
  ARCHIVE_IN_SPACES: 'archive-in-spaces',
  MERGE_IN_SPACES: 'merge-in-spaces',
  RTL_ENABLED: 'RTL-enabled',
  JIRA_ENABLED: 'enable-spaces-jira-integration',
  CONDITION_FOR_FLOATING_TICKET_PANEL_ENABLED: 'condition-for-floating-ticket-panel-enabled',
  USE_RECAPTCHA: 'use-recaptcha',
  COPILOT_AI_BUILDER: 'enable-builder-copilot',
  DUPLICATE_IN_SPACES: 'duplicate-in-spaces',
  PENDO_TRACKING_ENABLED: 'send-track-events-to-pendo',
  DATADOG_TRACKING_ENABLED: 'send-track-events-to-datadog',
  DRIFT_CHAT_ENABLED: 'drift-chat-enabled',
  SYSAID_CONNECT_ENABLED: 'sysaid-connect-enabled',
  ENABLE_AGENTS_SHORTCUT_IN_CHATBOT_COPILOT: 'enable-agents-shortcut-in-chatbot-copilot',
  ENABLE_ANALYTICS_COPILOT: 'enable-analytics-copilot',
};
